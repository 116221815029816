// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dmd-table-content {
    margin: 1em;
    text-align: center;
}

.dmd-table-content>div {
    width: 100%;
}

#devices-tools {
    border: 0;
    border-bottom: 1px solid black;
    background: rgba(0, 0, 0, 0);
    color: rgb(0, 0, 0);
    width: 100%;
    margin: 1em;
    font-size: 18px;
    padding-right: .5em;
    padding-left: .5em;
    text-align: center;
    margin-top: 2em;
}

#devices-tools::placeholder{
    color: rgb(100,100,100);
}`, "",{"version":3,"sources":["webpack://./css/devices.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAS;IACT,8BAA8B;IAC9B,4BAA4B;IAC5B,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".dmd-table-content {\n    margin: 1em;\n    text-align: center;\n}\n\n.dmd-table-content>div {\n    width: 100%;\n}\n\n#devices-tools {\n    border: 0;\n    border-bottom: 1px solid black;\n    background: rgba(0, 0, 0, 0);\n    color: rgb(0, 0, 0);\n    width: 100%;\n    margin: 1em;\n    font-size: 18px;\n    padding-right: .5em;\n    padding-left: .5em;\n    text-align: center;\n    margin-top: 2em;\n}\n\n#devices-tools::placeholder{\n    color: rgb(100,100,100);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
