import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';

import Logo from 'Img/logo.png'

class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onLogin() {
    let config = require('../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/auth"
  }

  emailValidator(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * La password deve:
   * Essere lunga almeno 8 caratteri
   * Contenere almeno una lettere maiuscola
   * Contenere almeno un numero
   * @param {} password 
   * @returns 
   */
  passwordValidator() {
    let password = this.state.password;
    if (password) {
      let passwordLowerCase = password.toLowerCase();
      if (password.length >= 8
        && password != passwordLowerCase
        && (password.indexOf(0) != -1 || password.indexOf(1) != -1 || password.indexOf(2) != -1 || password.indexOf(3) != -1 || password.indexOf(4) != -1
          || password.indexOf(5) != -1 || password.indexOf(6) != -1 || password.indexOf(7) != -1 || password.indexOf(8) != -1
          || password.indexOf(9) != -1)) {
        return true;
      }
    }
    return false;
  }

  confermaPasswordValidator() {
    if (this.state.confermaPassword && this.state.confermaPassword == this.state.password) {
      return true;
    }
    return false;
  }

  onRegister() {
    let firstNameError = false,
      lastNameError = false,
      emailError = false,
      passwordError = false;

    if (!this.props.registerLoading
      && this.state.firstName
      && this.state.lastName
      && this.state.email
      && this.state.password
      && this.emailValidator(this.state.email)
      && this.passwordValidator()
      && this.confermaPasswordValidator()) {

      this.props.register({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        number: this.state.number
      })
    } else {


      if (!this.state.firstName) {
        firstNameError = true;
      }
      if (!this.state.lastName) {
        lastNameError = true;
      }
      if (!this.state.email) {
        emailError = true;
      } else {
        if (!this.emailValidator(this.state.email)) {
          emailError = <>
            Il formato dell'indirizzo email non risulta essere corretto.
          </>
        }
      }
      if (!this.state.password) {
        passwordError = true;
      } else {
        if (!this.passwordValidator()) {
          passwordError = <>
            La password deve:<br />
            Essere lunga almeno 8 caratteri<br />
            Contenere almeno una lettere maiuscola<br />
            Contenere almeno un numero<br />
          </>;
        } else if (!this.confermaPasswordValidator()) {
          passwordError = <>
            Il campo Password e Conferma password devono essere uguali.
          </>;
        }
      }
    }

    if (firstNameError) {
      //
    }
    if (lastNameError) {
      //
    }
    if (emailError) {
      toast.error(emailError, {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (passwordError) {
      toast.error(passwordError, {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    this.setState({
      firstNameError: firstNameError,
      lastNameError: lastNameError,
      emailError: emailError,
      passwordError: passwordError
    });
  }

  render() {
    return (
      <div className="row mt-4">
        <div className="col">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div>
              <div className="row">
                <div className="col text-center">
                  <img src={Logo} width="250px" style={{ marginLeft: "0", width: "250px" }} />
                </div>
              </div>
              <hr />

              <div style={{ textAlign: "center" }}>
                <Avatar style={{ margin: "auto" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Registrazione
                </Typography>
              </div>
              <Alert className="mt-3" severity="info">Dopo aver effettuato la registrazione, verrà inviato un <strong>link di verifica</strong> all'indirizzo mail specificato.</Alert>
              <form noValidate style={{ marginTop: "1em" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={this.state.firstNameError}
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="Nome"
                      autoFocus
                      value={this.state.firstName}
                      onChange={(e) => { this.setState({ firstName: e.currentTarget.value }) }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.onRegister()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={this.state.lastNameError}
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Cognome"
                      name="lastName"
                      autoComplete="lname"
                      value={this.state.lastName}
                      onChange={(e) => { this.setState({ lastName: e.currentTarget.value }) }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.onRegister()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={this.state.emailError}
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Indirizzo Email"
                      name="email"
                      autoComplete="email"
                      value={this.state.email}
                      onChange={(e) => { this.setState({ email: e.currentTarget.value }) }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.onRegister()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={this.state.numberError}
                      variant="outlined"
                      fullWidth
                      id="number"
                      label="Cellulare"
                      name="number"
                      autoComplete="number"
                      value={this.state.number}
                      onChange={(e) => { this.setState({ number: e.currentTarget.value }) }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.onRegister()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={this.state.passwordError}
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={this.state.password}
                      onChange={(e) => { this.setState({ password: e.currentTarget.value }) }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.onRegister()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={this.state.passwordError}
                      variant="outlined"
                      required
                      fullWidth
                      name="confermaPassword"
                      label="Conferma password"
                      type="password"
                      id="confermaPassword"
                      autoComplete="current-password"
                      value={this.state.confermaPassword}
                      onChange={(e) => { this.setState({ confermaPassword: e.currentTarget.value }) }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.onRegister()
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                {this.props.registerLoading ?
                  <div style={{ textAlign: "center", marginTop: "1em" }}>
                    <CircularProgress />
                  </div>
                  :
                  <Button
                    type="button"
                    onClick={() => this.onRegister()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "1em" }}
                  >
                    Registrati
                  </Button>
                }

                <Grid container justify="flex-end" style={{ marginTop: "1em", textAlign: "center" }}>
                  <Grid item>
                    <Link onClick={() => this.onLogin()} variant="body2">
                      Hai già un account? Accedi
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Box mt={5} style={{ textAlign: "center" }}>
              Copyright &#169; DomoDroid 2021
            </Box>
          </Container>
        </div>
      </div>
    )
  }
}

export default Register
export { Register }