// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settingsOption{
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}
.settingsOptionName{
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 125%;
}
.settingsOptionValues{
    display:flex;
}
.settingsOptionValues div{
    background: #737373;
    padding: .75em 1em;
    color: rgb(255,255,255);
}

#settingsBtnSalva{
    border:0;
    padding: .5em;
    background-color: #00A400;
    color:white;
    border: 1px solid #019801;
    border-radius: 10px;
    outline: none;
    width: 5em;
    font-weight: bold;
    font-size: 110%;
}
#settingsBtnSalva:focus{
    outline: none;
}
#settings h1, #settings a{
    font-size: 175%;
    color:rgb(0,0,0)!important;
    display: inline-block;
}
#settings{
    margin: 1em;
    height: 90vh;
    position: relative;
}
#settingsBtns{
    display: flex;
    margin-top: 1em;
    justify-content: end;
    position: absolute;
    bottom: 1em;
    width: 100%;
}
#settingsTitle{
    border-bottom: 1px solid black;
    margin-bottom: 2em;
}

.settingsOptionSelected{
    background: #14489D!important;
}

#settingsBtnSalva.disabled{
    background: rgba(100,100,100,.5)!important;
}
`, "",{"version":3,"sources":["webpack://./css/settings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,QAAQ;IACR,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B,qBAAqB;AACzB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,eAAe;IACf,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;AACA;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".settingsOption{\n    display: flex;\n    justify-content: space-between;\n    margin-top: 1em;\n}\n.settingsOptionName{\n    font-weight: bold;\n    display: flex;\n    align-items: center;\n    font-size: 125%;\n}\n.settingsOptionValues{\n    display:flex;\n}\n.settingsOptionValues div{\n    background: #737373;\n    padding: .75em 1em;\n    color: rgb(255,255,255);\n}\n\n#settingsBtnSalva{\n    border:0;\n    padding: .5em;\n    background-color: #00A400;\n    color:white;\n    border: 1px solid #019801;\n    border-radius: 10px;\n    outline: none;\n    width: 5em;\n    font-weight: bold;\n    font-size: 110%;\n}\n#settingsBtnSalva:focus{\n    outline: none;\n}\n#settings h1, #settings a{\n    font-size: 175%;\n    color:rgb(0,0,0)!important;\n    display: inline-block;\n}\n#settings{\n    margin: 1em;\n    height: 90vh;\n    position: relative;\n}\n#settingsBtns{\n    display: flex;\n    margin-top: 1em;\n    justify-content: end;\n    position: absolute;\n    bottom: 1em;\n    width: 100%;\n}\n#settingsTitle{\n    border-bottom: 1px solid black;\n    margin-bottom: 2em;\n}\n\n.settingsOptionSelected{\n    background: #14489D!important;\n}\n\n#settingsBtnSalva.disabled{\n    background: rgba(100,100,100,.5)!important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
