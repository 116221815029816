import React from 'react';
import NewDevice from 'Img/newdevice.svg';
import { Link } from 'react-router-dom';

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class DevicesTools extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  onNuovoUtente() {
    this.props.setUserToEdit(null);

    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/nuovo_ospite";
  }

  render() {
    let devicesToolsStyle = {
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1em',
      margin: 'auto'
    };

    return (
      <div style={devicesToolsStyle}>
        <div id="devices-tools-btns">
            <Link id="devices-tools_newBtn" to={withContextPath("nuovo_dispositivo")}>{this.props.getLocale().GUESTS_NEW} <img src={NewDevice} /></Link>
        </div>
        <input id="devices-tools" type="text" placeholder={this.props.getLocale().DEVICES_TOOLS_SEARCH} onChange={(e) => this.props.setSearchedDeviceName (e.currentTarget.value)} />
      </div>
    )
  }
}

export default DevicesTools
export { DevicesTools }