import React from 'react';
import { Link } from 'react-router-dom';
import 'Css/device_card.css';
import { CircularProgress } from '@mui/material';
import Switch from '@mui/material/Switch';

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class DeviceCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pressed: {}
    };
  }

  componentWillMount() {
    if (this.props.isThemeDark()) {
      require('Css/device_card_dark.css');
    } else {
      require('Css/device_card.css');
    }
  }

  performPressedState(deviceName, value) {
    let pressed = this.state.pressed;
    pressed[deviceName] = value;
    this.setState({
      pressed: pressed
    });
  }

  onOpenControlPoint(serialNumber, deviceName, openType) {

    this.props.setShowModaleConferma({
      header: openType == 'impulse' ? "Apertura remota" : "Modifica stato",
      body: openType == 'impulse' ? <>Aprire in modo remoto il dispositivo <strong>{deviceName}</strong>?</> : <>Modificare lo stato del dispositivo <strong>{deviceName}</strong>?</>,
      esegui: () => {
        this.props.openControlPoint(serialNumber);
        this.props.setShowModaleConferma(null);
      },
      cancel: () => {
      }
    });
  }

  render() {
    let device = this.props.device;

    if (device.instantImpulse && !this.state.pressed[device.name]) {
      this.performPressedState(device.name, true);
    } else if (!device.instantImpulse && this.state.pressed[device.name]) {
      this.performPressedState(device.name, false);
    }

    let iconSVG = encodeURIComponent(
      '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M228.576 26.213v207.32h54.848V26.214h-54.848zm-28.518 45.744C108.44 96.58 41 180.215 41 279.605c0 118.74 96.258 215 215 215 118.74 0 215-96.26 215-215 0-99.39-67.44-183.025-159.057-207.647v50.47c64.6 22.994 110.85 84.684 110.85 157.177 0 92.117-74.676 166.794-166.793 166.794-92.118 0-166.794-74.678-166.794-166.795 0-72.494 46.25-134.183 110.852-157.178v-50.47z"></path></svg>');

    let configIconSVG = encodeURIComponent(
      '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"></path></svg>'
    );

    let iconDiv = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '2.5em',
      minWidth: '2.5em',
      maxWidth: '2.5em',
      height: '4.1em',
      overflow: 'show',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '.4em'
    };

    let configStyle = {
      backgroundImage: `url('data:image/svg+xml;utf8,${configIconSVG}')`,
    }

    return (
      <div className={"device_card " + (device.online? "online" : "")}>
        <div onClick={() => device.online ? this.onOpenControlPoint(device.serialNumber, device.name, device.openType) : {}}>
          <div style={iconDiv}>
            {device.openType == 'impulse' ?
              this.state.pressed[device.name] ?
                <CircularProgress style={{ width: '31px', height: '31px', opacity: device.online ? .6 : .3 }} />
                :
                <img src={`data:image/svg+xml,${iconSVG}`} alt="icon" style={{ width: '31px', opacity: device.online ? .6 : .3 }} />
              : device.openType == 'switch' ?
                <Switch disabled checked={!device.instantImpulse} />
                : ''}
            <label className="device_card-status">{device.online ? "ONLINE" : "OFFLINE"}</label></div>
          <label style={{ padding: '.5em', fontWeight: 'bold', fontSize: '18px', width: '100%', textAlign: 'center', opacity: '.9' }}>{device.name}</label>

        </div>
        <Link className="device_card-config" to={withContextPath("punto_controllo?id=" + device.id)} style={configStyle}></Link>
      </div >
    )
  }
}

export default DeviceCard
export { DeviceCard }