import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Background from 'Img/bg.png';

import { Profile, ControlPanel, Settings, Login, Register, ModaleConferma, Report, Messaggi, SideBar, ModaleSMS, PasswordRecovery, PasswordRecoveryForm } from 'Containers/main_layout';
import { NuovoOspite, Devices, NuovoDispositivo, Guests, PuntoControllo, Tessere } from 'Containers/functions';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.keyComponent = 0;
    let config = require('../../../../config/config.json');

    let urlKey = "";
    if (window.location.href.indexOf("?dmd=") !== -1) {
      urlKey = window.location.href.substring(window.location.href.indexOf("?dmd=") + 5);
    }
    let toastMessage = "";
    if (urlKey) {
      toastMessage = CryptoAES.decrypt(urlKey, config.phrase).toString(CryptoENC);
    } else if (window.location.href.indexOf("2r3c2practivated131c") !== -1) {
      setTimeout(() => {
        this.props.logout();
      }, 500);
      toastMessage = "Account attivato con successo.";
    }

    this.state = {
      config: config
    };

    if (toastMessage) {
      toast.info(toastMessage, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  hideSideBar(e) {
    var text = e.target.id;
    var parentText = e.target.parentElement.innerText;
    var suParentText = e.target.parentElement.parentElement.outerText;
    var className = e.target.className;
    var outerText = e.target.outerText;

    if (
      text !== "sidebar" &&
      className &&
      className.indexOf("sidebar-btn") === -1 &&
      outerText !== "ControlPanel" &&
      parentText !== "ControlPanel" &&
      suParentText !== "ControlPanel" &&
      outerText !== "Punti controllo" &&
      parentText !== "Punti controllo" &&
      suParentText !== "Punti controllo" &&
      outerText !== "Chiavi" &&
      parentText !== "Chiavi" &&
      suParentText !== "Chiavi"
    ) {
      this.props.setSideBarVisibility(false);
    }
  }

  render() {
    this.props.getStompClient();

    $(() => {
      if (this.props.isThemeDark()) {
        $("body").css("background", "rgb(0,0,0)");
      }
    });

    return (
      <React.Fragment>
        {this.props.getUser() ? (
          this.props.deviceSetupProcedureStarted || this.props.guestRegistrationProcedureStarted ? (
            <Routes>
              <Route path={process.env.CONTEXT_PATH + '/nuovo_dispositivo'} element={<NuovoDispositivo />} />
              <Route path={process.env.CONTEXT_PATH + '/dispositivi'} element={<Devices />} />
              <Route path={process.env.CONTEXT_PATH + '/nuovo_ospite'} element={<NuovoOspite />} />
            </Routes>
          ) : (
            <>
              <main role="main" id="main"
                style={{
                  background: this.props.isThemeDark() ? 'black' : `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${Background})`,
                  height: "100vh"
                }}>
                {/* <Header></Header> */}

                <Routes>
                  <Route path={process.env.CONTEXT_PATH + '/settings'} element={<Settings />} />
                  <Route path={process.env.CONTEXT_PATH + '/messaggi'} element={<Messaggi />} />
                  <Route path={process.env.CONTEXT_PATH + '/report'} element={<Report />} />
                  <Route path={process.env.CONTEXT_PATH + '/nuovo_dispositivo'} element={<NuovoDispositivo />} />
                  <Route path={process.env.CONTEXT_PATH + '/guests'} element={<Guests />} />
                  <Route path={process.env.CONTEXT_PATH + '/tessere'} element={<Tessere />} />
                  <Route path={process.env.CONTEXT_PATH + '/nuovo_ospite'} element={<NuovoOspite />} />
                  <Route path={process.env.CONTEXT_PATH + '/dispositivi'} element={<Devices />} />
                  <Route path={process.env.CONTEXT_PATH + '/punto_controllo'} element={<PuntoControllo />} />
                  <Route path={process.env.CONTEXT_PATH + '/control_panel'} element={<ControlPanel />} />
                  <Route path={process.env.CONTEXT_PATH + '/profile'} element={<Profile />} />
                  <Route path={process.env.CONTEXT_PATH + '/*'} element={<Navigate to={process.env.CONTEXT_PATH + '/dispositivi'} />} />
                  <Route path={'/*'} element={<Navigate to={process.env.CONTEXT_PATH + '/dispositivi'} />} />
                </Routes>

                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <ModaleConferma />
                <ModaleSMS />
              </main>
              <SideBar />
            </>
          )
        ) : (
          <main role="main" id="main">
            <Routes>
              <Route path={process.env.CONTEXT_PATH + '/auth'} element={<Login />} />
              <Route path={process.env.CONTEXT_PATH + '/password_recovery'} element={<PasswordRecovery />} />
              <Route path={process.env.CONTEXT_PATH + '/password_recovery_f'} element={<PasswordRecoveryForm />} />
              <Route path={process.env.CONTEXT_PATH + '/register'} element={<Register />} />
              <Route path={process.env.CONTEXT_PATH + '/*'} element={<Navigate to={process.env.CONTEXT_PATH + '/auth'} />} />
              <Route path={'/*'} element={<Navigate to={process.env.CONTEXT_PATH + '/auth'} />} />
            </Routes>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </main>
        )}
      </React.Fragment>
    );
  }
}

export default MainLayout;
export { MainLayout };