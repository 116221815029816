import React from 'react';

import DevicesIcon from 'Img/sidebar/devicesIcon.svg';
import GuestsIcon from 'Img/sidebar/guestsIcon.svg';
import ReportIcon from 'Img/sidebar/reportIcon.svg';
import PanelIcon from 'Img/sidebar/panelIcon.svg';

import DevicesIconSelected from 'Img/sidebar/devicesIcon_selected.svg';
import GuestsIconSelected from 'Img/sidebar/guestsIcon_selected.svg';
import ReportIconSelected from 'Img/sidebar/reportIcon_selected.svg';
import PanelIconSelected from 'Img/sidebar/panelIcon_selected.svg';

import DevicesIconDark from 'Img/sidebar/dark/devicesIcon_dark.svg';
import GuestsIconDark from 'Img/sidebar/dark/guestsIcon_dark.svg';
import ReportIconDark from 'Img/sidebar/dark/reportIcon_dark.svg';
import PanelIconDark from 'Img/sidebar/dark/panelIcon_dark.svg';

import DevicesIconDarkSelected from 'Img/sidebar/dark/devicesIcon_dark_selected.svg';
import GuestsIconDarkSelected from 'Img/sidebar/dark/guestsIcon_dark_selected.svg';
import ReportIconDarkSelected from 'Img/sidebar/dark/reportIcon_dark_selected.svg';
import PanelIconDarkSelected from 'Img/sidebar/dark/panelIcon_dark_selected.svg';

import { Link } from "react-router-dom";

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class SideBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      delayItemsMenu: 300,
      timbraPressed: false
    };
  }

  componentWillMount() {
    if (this.props.isThemeDark()) {
      require('Css/sidebar_dark.css');
    } else {
      require('Css/sidebar.css');
    }
  }

  onToggleSidebar() {
    this.props.setSideBarVisibility(!this.props.showSideBar);
  }

  getIcon(voice, def, dark, defaultSelected, darkSelected) {
    if (this.props.isThemeDark()) {
      if (this.props.sidebarVoice == voice) {
        return <img src={darkSelected} width="32" height="32" />
      }
      return <img src={dark} width="32" height="32" />
    } else {
      if (this.props.sidebarVoice == voice) {
        return <img src={defaultSelected} width="32" height="32" />
      }
      return <img src={def} width="32" height="32" />
    }
  }

  render() {
    let locale = this.props.getLocale();

    if (!this.state.voiceMenuSelected) {
      let flagVoiceMenuSelected = false;

      if (flagVoiceMenuSelected) {
        this.setState({ voiceMenuSelected: true });
      }
    }

    return (
      <>
        <div id={"dmd-sidebar"} className={this.props.sidebarVoice}>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "dispositivi" ? " selected" : "")} to={withContextPath("dispositivi")}>
            {this.getIcon("dispositivi", DevicesIcon, DevicesIconDark, DevicesIconSelected, DevicesIconDarkSelected)}
            <label>{locale.SIDEBAR_DEVICES}</label>
          </Link>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "ospiti" ? " selected" : "")} to={withContextPath("guests")}>
            {this.getIcon("ospiti", GuestsIcon, GuestsIconDark, GuestsIconSelected, GuestsIconDarkSelected)}
            <label>{locale.SIDEBAR_GUESTS}</label>
          </Link>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "report" ? " selected" : "")} to={withContextPath("report")}>
            {this.getIcon("report", ReportIcon, ReportIconDark, ReportIconSelected, ReportIconDarkSelected)}
            <label>{locale.SIDEBAR_REPORT}</label>
          </Link>
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "pannello" ? " selected" : "")} to={withContextPath("control_panel")}>
            {this.getIcon("pannello", PanelIcon, PanelIconDark, PanelIconSelected, PanelIconDarkSelected)}
            <label>{locale.SIDEBAR_DASHBOARD}</label>
          </Link>
        </div>
      </>
    )
  }
}

export default SideBar
export { SideBar }