import React from 'react';

import Dashbox from './dashbox';
import { BiRfid } from "react-icons/bi";
import { Link } from 'react-router-dom';
import 'Css/control_panel.css';
import { FaUserCircle } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { HiDeviceTablet } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class ControlPanel extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0;
  }

  componentDidMount() {
    this.props.setSidebarVoice("pannello");
  }

  onNuovoUtente() {
    this.props.setUserToEdit(null);
  }

  render() {
    let mobileVer = window.outerWidth < 455;

    let welcomeRowStyle = {
      overflow: "hidden",
      color: "white",
      height: "auto",
      padding: ".5em",
      background: "rgba(0,132,213, 1)",
      marginLeft: "0"
    }

    let welcomeRowTextStyle = {
      fontSize: mobileVer ? "20px" : "25px",
      fontFamily: "bitter,sans-serif",
      fontWeight: "700",
      textTransform: "uppercase",
    }

    return (
      <div id="controlPanel">

        <div className="row mt-1 text-center" style={welcomeRowStyle}>
          <div className="col">
            <div style={welcomeRowTextStyle}>Ciao {this.props.getUser().name} {this.props.getUser().lastName}</div>
            <div className="row mt-2">
              <div className="col" style={{ margin: 'auto' }}>
                <h6 style={{ fontSize: mobileVer ? "0.9rem" : "initial" }}>All'interno del tuo pannello di controllo potrai accedere velocemente ai collegamenti per gestire dispositivi, utenti e configurazioni.</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="dashbox-container">
          <Dashbox to={withContextPath("dispositivi")} label="DISPOSITIVI" icon={<HiDeviceTablet />} />
          <Dashbox to={withContextPath("guests")} label="OSPITI" icon={<FaUserCircle />} />
          <Dashbox to={withContextPath("report")} label="REPORT" icon={<TbReportAnalytics />} />
          <Dashbox to={withContextPath("messaggi")} label="TEMPLATE SMS" icon={<i className="fas fa-envelope" aria-hidden="true"></i>} />
        </div>

        <div className="controlPanel-btnBox blue">
          <Link to={withContextPath("profile")}><CgProfile /> PROFILO</Link>
        </div>

        <div className="controlPanel-btnBox orange section">
          <Link className={"sidebar-link" + (this.props.sidebarVoice == "tessere" ? " selected" : "")} to={withContextPath("tessere")}>
            <BiRfid />
            <label>TESSERE</label>
          </Link>
        </div>

        <div className="controlPanel-btnBox blue">
          <Link to={withContextPath("nuovo_ospite")}>NUOVO OSPITE</Link>
        </div>

        <div className="controlPanel-btnBox blue mt-4">
          <Link to={withContextPath("nuovo_dispositivo")}>NUOVO DISPOSITIVO</Link>
        </div>

        <div className="controlPanel-btnBox blue mt-4">
          <Link to={withContextPath("settings")}>IMPOSTAZIONI</Link>
        </div>


        <div className="controlPanel-btnBox red" id="logout">
          <button onClick={() => this.props.logout()} >DISCONNETTI</button>
        </div>
      </div>
    )
  }
}

export default ControlPanel
export { ControlPanel }