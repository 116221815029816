// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dmd-table-content {
    margin: 1em;
    text-align: center;
}

.dmd-table-content>div {
    width: 100%;
}

#devices-tools {
    border: 0;
    border-bottom: 1px solid black;
    background: rgba(0, 0, 0, 0);
    color: rgb(0, 0, 0);
    width: 100%;
    margin: 1em;
    font-size: 18px;
    padding-right: .5em;
    padding-left: .5em;
    text-align: center;
    margin-top: 2em;
}

#devices-tools::placeholder{
    color: rgb(100,100,100);
}

#devices-tools-btns{
    width:100%;
    display: flex;
    justify-content: end;
}
#devices-tools-btns img{
    margin-left: .15em;
}
#devices-tools_newBtn{
    background-color: #14489D;
    border:0;
    border-radius: 8px;
    width:fit-content;
    padding: .5em .3em .5em .5em;
    color: rgb(255,255,255);
    font-weight: 600;
    font-size: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .25em;
}`, "",{"version":3,"sources":["webpack://./css/devices.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAS;IACT,8BAA8B;IAC9B,4BAA4B;IAC5B,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,QAAQ;IACR,kBAAkB;IAClB,iBAAiB;IACjB,4BAA4B;IAC5B,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;;IAEf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".dmd-table-content {\n    margin: 1em;\n    text-align: center;\n}\n\n.dmd-table-content>div {\n    width: 100%;\n}\n\n#devices-tools {\n    border: 0;\n    border-bottom: 1px solid black;\n    background: rgba(0, 0, 0, 0);\n    color: rgb(0, 0, 0);\n    width: 100%;\n    margin: 1em;\n    font-size: 18px;\n    padding-right: .5em;\n    padding-left: .5em;\n    text-align: center;\n    margin-top: 2em;\n}\n\n#devices-tools::placeholder{\n    color: rgb(100,100,100);\n}\n\n#devices-tools-btns{\n    width:100%;\n    display: flex;\n    justify-content: end;\n}\n#devices-tools-btns img{\n    margin-left: .15em;\n}\n#devices-tools_newBtn{\n    background-color: #14489D;\n    border:0;\n    border-radius: 8px;\n    width:fit-content;\n    padding: .5em .3em .5em .5em;\n    color: rgb(255,255,255);\n    font-weight: 600;\n    font-size: 100%;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    column-gap: .25em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
