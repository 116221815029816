import React from 'react';
import 'Css/settings.css';
import { IoChevronBackSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const withContextPath = (path) => `${process.env.CONTEXT_PATH}/${path}`;

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.setSidebarVoice("pannello");
  }
  
  changeUserAttribute(attr, value) {
    if (this.state.user) {
      let user = this.state.user;
      user[attr] = value;
      this.setState({
        user: user
      });
    }
  }

  render() {
    let locale_it = require('../../../config/locale_it.json');

    if (!this.state.user) {
      this.setState({
        user: this.props.getUser()
      })
    }

    return (
      <div id="settings">
        <div id="settingsTitle">
          <Link to={withContextPath("control_panel")} style={{ marginRight: '.5em' }}>
            <IoChevronBackSharp />
          </Link>
          <h1>Impostazioni</h1>
        </div>

        <div className="settingsOption">
          <div className="settingsOptionName">
            Tema
          </div>
          <div className="settingsOptionValues">
            <div onClick={() => this.changeUserAttribute("themeMode", "LIGHT")} className={!this.state.user?.themeMode || this.state.user?.themeMode == 'LIGHT' ? 'settingsOptionSelected' : ''}>
              Chiaro
            </div>
            <div onClick={() => this.changeUserAttribute("themeMode", "DARK")} className={this.state.user?.themeMode && this.state.user?.themeMode == 'DARK' ? 'settingsOptionSelected' : ''}>
              Scuro
            </div>
          </div>
        </div>

        <div className="settingsOption">
          <div className="settingsOptionName">
            Lingua
          </div>
          <div className="settingsOptionValues">
            <select onChange={(e) => this.changeUserAttribute("language", e.currentTarget.value)}>
              <option value="ITA" selected={this.state.user?.language == "ITA" ? "selected" : ""}>Italiano</option>
              <option value="ENG" selected={this.state.user?.language == "ENG" ? "selected" : ""}>English</option>
            </select>
          </div>
        </div>

        <div id="settingsBtns">
          <button id="settingsBtnSalva" className={this.props.settingsLoading ? "disabled" : ""} onClick={() => this.props.settingsLoading ? "" : this.props.saveSettings(this.state.user)}>SALVA</button>
        </div>
      </div>
    )
  }
}

export default Settings
export { Settings }