import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FaUser } from "react-icons/fa6";

import { ModificaOspite } from 'Containers/functions';
import NewGuest from 'Img/newguest.svg';

class Guests extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {}

    this.props.readUsers();
  }

  componentWillMount() {
    if (this.props.isThemeDark()) {
      require('Css/guests_dark.css');
    } else {
      require('Css/guests.css');
    }
  }

  componentDidMount() {
    this.props.setSidebarVoice("ospiti");
  }

  onNuovoOspite() {
    this.props.setUserToEdit(null);

    let config = require('../../../../config/config.json');
    const context = `/${config.context}`
    location.href = context + "/nuovo_ospite";
  }

  onGestisci(user) {
    this.props.getAll();
    this.props.editGuest(user.id);
  }

  getGuestsHtml() {
    let lastLetter = "";
    return this.props.users && this.props.users.length > 0 ? this.props.users.map((user) => {
        if(this.state.search && user.name.toLowerCase().indexOf(this.state.search.toLowerCase()) == -1 && user.lastName.toLowerCase().indexOf(this.state.search.toLowerCase()) == -1){
          return "";
        }

        let showLetter = user.name[0] != lastLetter;
        lastLetter = user.name[0];
        return (
          <>
            {showLetter ? <div style={{ color: 'rgb(120,120,120)', marginBottom: '.5em' }}>{user.name[0]}</div> : ""}
            <div className="guests-guest-row" onClick={() => this.onGestisci(user)}>
              <FaUser />
              <label>{user.name} {user.lastName}</label>
            </div>
          </>
        )
    }) : null;
  }

  render() {
    let guestsHtml = this.getGuestsHtml();

    return (
      <>
        <div id="guests">
          <div id="guests_tools">
            <button id="guests_newBtn" onClick={() => this.onNuovoOspite()}>{this.props.getLocale().GUESTS_NEW} <img src={NewGuest} /></button>
            <div id="guests_search">
              <input type="text" placeholder={this.props.getLocale().GUESTS_SEARCH} onChange={(e) => this.setState({ search: e.currentTarget.value })} />
            </div>
          </div>

          {this.props.readUsersLoading ?
            <>
              <div className="row mt-4 text-center" style={{ overflow: "hidden" }}>
                <div className="col-5" style={{ margin: "auto", textAlign: "center" }}>
                  <CircularProgress />
                </div>
              </div>
              <div id="guests_loading" style={{ margin: "auto", textAlign: "center" }}>
                {this.props.getLocale().GUESTS_READING}
              </div>
            </>
            :
            <>
              {guestsHtml ?
                <div id="guests-list">
                  {guestsHtml}
                </div>
                :
                this.props.getLocale().GUESTS_NO_DATA
                }
            </>
          }
        </div>

        <ModificaOspite></ModificaOspite>
      </>
    )
  }
}

export default Guests
export { Guests }