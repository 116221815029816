// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dmd-sidebar {
    width: 100%;
    height: 5em;
    background: rgb(0,0,0);
    box-shadow: 1px 1px 4px 5px rgba(110,110,110,.3);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    z-index: 99;
    display:flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 2px;
    padding: .5em;
    padding-bottom: 1em;
    position: fixed;
    bottom: 0;
    font-size: 80%;
}
#dmd-sidebar .sidebar-link{
    color: white;
    display: inline-block;
    border: 0;
    border-right: 2px solid rgba(255,255,255,.2);
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: .3em;
}

#dmd-sidebar .sidebar-link svg{
    font-size: 170%;
}
#dmd-sidebar .sidebar-link img{
    width: 22px;
    height: 22px;
}

#dmd-sidebar .sidebar-link:last-child{
    border-right: 0;
}

#dmd-sidebar .sidebar-link > *{
    display: block;
}

#dmd-sidebar .sidebar-link {
    text-decoration: none;
}

#dmd-sidebar .sidebar-link.selected{
    /* font-weight: bold; */
    color: #00B7FF!important
}
#dmd-sidebar label{
    position:relative;
    top: 6px;
}`, "",{"version":3,"sources":["webpack://./css/sidebar_dark.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,gDAAgD;IAChD,6BAA6B;IAC7B,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,cAAc;AAClB;AACA;IACI,YAAY;IACZ,qBAAqB;IACrB,SAAS;IACT,4CAA4C;IAC5C,UAAU;IACV,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB;AACJ;AACA;IACI,iBAAiB;IACjB,QAAQ;AACZ","sourcesContent":["#dmd-sidebar {\n    width: 100%;\n    height: 5em;\n    background: rgb(0,0,0);\n    box-shadow: 1px 1px 4px 5px rgba(110,110,110,.3);\n    border-top-right-radius: 20px;\n    border-top-left-radius: 20px;\n    z-index: 99;\n    display:flex;\n    justify-content: space-around;\n    align-items: center;\n    column-gap: 2px;\n    padding: .5em;\n    padding-bottom: 1em;\n    position: fixed;\n    bottom: 0;\n    font-size: 80%;\n}\n#dmd-sidebar .sidebar-link{\n    color: white;\n    display: inline-block;\n    border: 0;\n    border-right: 2px solid rgba(255,255,255,.2);\n    width:100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding: .3em;\n}\n\n#dmd-sidebar .sidebar-link svg{\n    font-size: 170%;\n}\n#dmd-sidebar .sidebar-link img{\n    width: 22px;\n    height: 22px;\n}\n\n#dmd-sidebar .sidebar-link:last-child{\n    border-right: 0;\n}\n\n#dmd-sidebar .sidebar-link > *{\n    display: block;\n}\n\n#dmd-sidebar .sidebar-link {\n    text-decoration: none;\n}\n\n#dmd-sidebar .sidebar-link.selected{\n    /* font-weight: bold; */\n    color: #00B7FF!important\n}\n#dmd-sidebar label{\n    position:relative;\n    top: 6px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
