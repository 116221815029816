import { observable, action, makeObservable } from "mobx";

class ThemeStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable config = require('../../config/config.json');

  @action
  isThemeDark() {
    return this.rootStore.userStore.getUser()?.themeMode === 'DARK';
  }

  @action
  getLocale(){
    let userLanguage = this.rootStore.userStore.getUser().language;

    if(!userLanguage || userLanguage == "ITA"){
      return require('../../config/locale_it.json');
    }else if(userLanguage == "ENG"){
      return require('../../config/locale_eng.json')
    }
  }

}

export default ThemeStore;